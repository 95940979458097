<template>
  <div class="my-8 callHistory">
    <!-- <div class="tenant-overlay" v-if="loading"> -->
    <!-- <p :style="'color:' + color"> -->
    <!-- {{ message }} -->
    <!-- <br /> -->
    <!-- <v-progress-circular
          indeterminate
          color="white"
          :size="60"
        ></v-progress-circular> -->
    <!-- <v-progress-linear
          v-if="showLoader"
          indeterminate
          color="yellow"
        ></v-progress-linear> -->
    <!-- </p> -->
    <!-- </div> -->

    <v-row>
      <v-col cols="2"> </v-col>
      <v-col cols="8">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates text-center"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col
                md="4"
                sm="12"
                :style="
                  currentAppLocale == 'ar'
                    ? 'text-align:right'
                    : 'text-align:left'
                "
              >
                <h6 class="form-header" style="color: #0275e3; font-size: 25px">
                  {{ $t("Schools") }}
                </h6>
              </v-col>
              <v-col class=""> </v-col>
              <!-- ==================================================filters================================================== -->
              <v-col md="3" sm="12" class="text-right">
                <label class="search" for="search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    v-model="search"
                    @keyup.enter="getData"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                  />
                  <v-icon
                    @click="getData"
                    :title="$t('Search')"
                    class="search-icon"
                    >search</v-icon
                  >
                </label>
              </v-col>
              <v-col md="1" sm="12" class="text-right">
                <v-icon
                  large
                  @click="OpenDialog"
                  class="main-color"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.sub_domain="{ item }">
            <!-- <v-btn icon class="mx-2" @click="copyText(item.tenant_url)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn> -->
            <a class="main-color" @click="open(item.tenant_url)">{{
              item.sub_domain
            }}</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-2"
                  @click="copyText(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Copy credentials") }}</span>
            </v-tooltip>
          </template>
          <!--   -->
        </v-data-table>
        <div class="text-center my-2">
          <v-pagination
            v-if="length > 1"
            v-model="page"
            :length="length"
            :total-visible="totalVisible"
            circle
          ></v-pagination>
        </div>
      </v-col>
      <v-col cols="2"> </v-col>
    </v-row>
    <!-- Snak -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline main-color">Add New School</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form" class="py-4">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label class="mt-3">{{ $t("School Name") }} </label>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[validationRules.required]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label class="mt-3">{{ $t("School Subdomain") }} </label>
                  <v-text-field
                    v-model="editedItem.sub_domain"
                    :rules="[validationRules.required]"
                    solo
                    @keyup="removeSpaces"
                  ></v-text-field>
                  <div v-if="validation_errors.sub_domain">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.sub_domain"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label class="mt-3">{{ $t("School Timezone") }} </label>
                  <!-- <v-select
                    :items="timezones"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.timezone"
                    :rules="[validationRules.required]"
                    solo
                  ></v-select> -->
                  <v-autocomplete
                    :items="timezones"
                    item-text="name"
                    item-value="id"
                    v-model="editedItem.timezone"
                    :rules="[validationRules.required]"
                    solo
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label class="mt-3">{{ $t("Admin email address") }} </label>
                  <v-text-field
                    v-model="editedItem.super_admin_email"
                    :rules="[validationRules.required, validationRules.email]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label class="mt-3">{{ $t("Admin password") }} </label>
                  <v-text-field
                    v-model="editedItem.super_admin_password"
                    :rules="[validationRules.required]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel main-bg-border-color" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save main-bg-color"
            @click="showConfirmDialog"
            >{{ $t("Create") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirm"
      persistent
      max-width="500"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline main-color">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{ $t("Are you sure ?") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="modal-btn-cancel main-bg-border-color"
            @click="dialogConfirm = false"
          >
            {{ $t("No") }}
          </v-btn>
          <v-btn
            class="modal-btn-save main-bg-color"
            @click="save"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "Schools",
  mixins: [validationMixin],
  data() {
    return {
      logoPath: require("../../assets/Iskola_logo.png"),

      search: "",
      message: "Please wait, We're creating your tenant",
      color: "#ffeb3b",
      showLoader: false,
      dialog: false,
      dialogConfirm: false,
      valid: false,
      loading: false,
      options: {
        itemsPerPage: 15,
      },

      circle: true,
      page: 1,
      length: 1,
      totalVisible: 5,
      headers: [
        {
          text: this.$i18n.t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$i18n.t("Subdomain"),
          value: "sub_domain",
          sortable: false,
        },

        {
          text: this.$i18n.t("Timezone"),
          value: "timezone",
          sortable: false,
        },
        {
          text: this.$i18n.t("Admin email address"),
          value: "super_admin_email",
          sortable: false,
        },
        {
          text: this.$i18n.t("Admin password"),
          value: "plain_password",
          sortable: false,
        },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      timezones: [],
      editedItem: {
        name: "",
        sub_domain: "",
        timezone: "",
        super_admin_email: "",
        super_admin_password: "",
      },
      validation_errors: {
        sub_domain: "",
      },
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
    };
  },
  watch: {
    page: function () {
      this.getData();
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  methods: {
    copyText(item) {
      console.log(item);
      var data =
        "URL: " +
        item.tenant_url +
        "\nAdmin Username: " +
        item.super_admin_email +
        "\nAdmin Password: " +
        item.plain_password;

      // if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(data);
      // }
    },

    getData() {
      this.$http
        .get(
          this.getLandlordApiUrl +
            "/tenants?page=" +
            this.page +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.options.itemsPerPage = response.data.data.per_page;
          this.length = response.data.data.last_page;
        });
    },
    OpenDialog() {
      this.dialog = true;
    },
    showConfirmDialog() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.dialogConfirm = true;
      }
    },
    save() {
      // this.showLoader = true;
      this.loading = true;
      this.$http
        .post(this.getLandlordApiUrl + "/tenants", this.editedItem, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == true) {
            this.validation_errors = response.data.status.validation_errors;

            if (response.data.status.validation_errors.length == 0) {
              this.errMessage = response.data.status.message;
            }
          } else {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.close();
            this.getData();
          }

          // if (response.data.status.error == false) {
          //   this.color = "#04a104";
          // } else {
          //   this.color = "#c90505";
          // }
          // this.showLoader = false;
          // this.message = response.data.status.message;
          //   setTimeout(() => {
          //     this.loading = false;
          //     this.close();
          //   }, 3000);
          //   this.getData();
          this.dialogConfirm = false;
        });
    },
    close() {
      this.reset();
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.editedItem = {
        name: "",
        sub_domain: "",
        timezone: "",
        super_admin_email: "",
        super_admin_password: "",
      };
      this.validation_errors = {
        sub_domain: "",
      };
    },
    reset() {
      this.editedItem = {
        name: "",
        sub_domain: "",
        timezone: "",
        super_admin_email: "",
        super_admin_password: "",
      };
      // this.message = "Please wait, We're creating your tenant";
      // this.color = "#ffeb3b";
    },
    removeSpaces() {
      this.editedItem.sub_domain = this.editedItem.sub_domain.replace(
        /\s/g,
        ""
      );
    },
    open(url) {
      window.open(url, "_blank");
    },
    getTimezones() {
      this.$http
        .get(this.getLandlordApiUrl + "/tenants/timezones", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.timezones = response.data.data;
        });
    },
  },
  mounted() {
    this.getData();
    this.getTimezones();
  },
};
</script>

<style lang="scss" scoped>
.main-color {
  color: #1976d2 !important;
}
.main-bg-color {
  background-color: #1976d2 !important;
  color: #ffffff !important;
  border: 2px solid #1976d2 !important;
  margin-right: 12px !important;
}
.main-bg-border-color {
  border: 2px solid #1976d2 !important;
  color: #1976d2 !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 !important;
}
</style>
>
